import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useQueryClient } from "@tanstack/react-query";
import Popover from "core/components/Popover.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons";
import { ThemeProvider } from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";
import { Tooltip } from "react-tooltip";
import { DateTime } from "luxon";

import Table, {
  HeaderWithTooltip,
  SearchableTableCell,
  TextWithIconTableCell,
} from "core/components/Table";
import Pill from "core/components/Pill";
import Link from "core/components/Link";
import BiPortalUserTooltip from "./BiPortalUserTooltip";
import ActivateUserModal from "./ActivateUserModal";
import DeactivateUserModal from "./DeactivateUserModal";
import {
  DIRECT_DATA_ACCESS_STATUS_LABEL,
  FLAG_KEYS,
  REVERSE_LOOKUP_ROLES,
  ROLES,
  TABLEAU_ROLES,
} from "core/constants";
import DisableUserDataAccessModal from "./DisableUserDataAccessModal";
import theme from "theme";
import { enableUserDataAccess } from "./actions";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";
import { getLastActiveCredential } from "./utilities";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";
import useNavigation from "core/hooks/useNavigation.js";

const columnHelper = createColumnHelper();

const userDataAccessTooltipId = "userDataAccessTooltip";

const UserDataAccessTooltip = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "8px 11px",
        }}
      >
        <Typography variant="sub-text" color="white" noMargin>
          Credential generated on{" "}
          {DateTime.fromMillis(data.generated_timestamp).toFormat("D")}
        </Typography>
      </div>
    </ThemeProvider>
  );
};

const staticColumns = [
  columnHelper.accessor("name", {
    header: "Full Name",
    sortingFn: "textCaseSensitive",
    minSize: 250,
    customStyles: {
      flex: "1 1 250px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: (props) => {
      return (
        <TextWithIconTableCell
          position="left"
          icon={faUser}
          primaryColor="#0097C0"
        >
          <Link
            to={`/admin/org-management/${props.row.original.organizationId}/users/${props.row.original.email}/profile`}
            style={{
              fontSize: "14px",
              flexGrow: 1,
              overflowX: "clip",
              textOverflow: "ellipsis",
            }}
            state={{ from: "Users" }}
          >
            <SearchableTableCell
              searchConfig={props.table.options.meta.searchConfig}
              columnId={props.column.id}
              placeholder="Missing name"
            >
              {props.getValue()}
            </SearchableTableCell>
          </Link>
        </TextWithIconTableCell>
      );
    },
  }),
  columnHelper.accessor("email", {
    header: "Email",
    minSize: 250,
    customStyles: {
      flex: "1 1 250px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: SearchableTableCell,
  }),
  columnHelper.accessor((row) => ROLES[row.role], {
    id: "role",
    header: "Role",
    minSize: 65,
    customStyles: {
      flex: "1 1 65px",
      display: "flex",
      justifyContent: "flex-start",
    },
  }),
  columnHelper.accessor(
    (row) => TABLEAU_ROLES[row.tableauConfig?.tableauLicense] || "—",
    {
      id: "biRole",
      minSize: 65,
      customStyles: {
        flex: "1 1 65px",
        display: "flex",
        justifyContent: "flex-start",
      },
      header: (
        <HeaderWithTooltip info={<BiPortalUserTooltip />}>
          BI Role
        </HeaderWithTooltip>
      ),
    }
  ),
  columnHelper.accessor("direct_data_access_enabled", {
    id: "directDataAccess",
    header: "Direct Data Access",
    minSize: 65,
    customStyles: {
      flex: "1 1 65px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: (props) => {
      const data_access_info = props.row.original.data_access_info;
      const mostRecentCredential = data_access_info
        ? getLastActiveCredential(data_access_info)
        : null;

      const text = props.getValue()
        ? DIRECT_DATA_ACCESS_STATUS_LABEL.ENABLED
        : DIRECT_DATA_ACCESS_STATUS_LABEL.DISABLED;

      const variant = props.getValue() ? "success" : "default";

      return (
        <Pill
          text={text}
          size="small"
          variant={variant}
          tooltipId={userDataAccessTooltipId}
          tooltipStrategy="fixed"
          tooltipContent={
            props.getValue() && mostRecentCredential
              ? renderToStaticMarkup(
                  <UserDataAccessTooltip data={{ ...mostRecentCredential }} />
                )
              : ""
          }
        />
      );
    },
  }),
  columnHelper.accessor((row) => row.active, {
    id: "status",
    header: "Status",
    minSize: 50,
    customStyles: {
      flex: "1 1 50px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: (props) => {
      const text = props.getValue() ? "Active" : "Inactive";

      const variant = props.getValue() ? "success" : "default";

      return <Pill text={text} size="small" variant={variant} />;
    },
  }),
];

const UserTable = (props) => {
  const {
    data,
    isRefreshing,
    searchFilter = { searchValue: "", columns: [] },
    isLoading,
    canUpdate,
    userRole,
  } = props;

  const navigate = useNavigate();
  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  const [userToDeactivate, setUserToDeactivate] = useState(null);
  const [userToActivate, setUserToActivate] = useState(null);
  const [userToDisableDataAccess, setUserToDisableDataAccess] = useState(null);

  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const enableDataAccess = useAuthenticatedMutation(enableUserDataAccess);

  const dynamicColumns = [
    columnHelper.display({
      id: "actions",
      size: 75,
      customStyles: {
        flex: "1 1 75px",
        display: "flex",
        justifyContent: "flex-end",
      },
      cell: ({ row }) => {
        const openRowMenuId = row.original.email;
        const openRowMenuUser = data.find(
          (user) => user.email === openRowMenuId
        );
        const enableUserUpdate =
          userRole === ROLES.systemAdmin ||
          [
            REVERSE_LOOKUP_ROLES[ROLES.orgAdmin],
            REVERSE_LOOKUP_ROLES[ROLES.orgUser],
          ].includes(openRowMenuUser.role);

        const actions = hasDataAccessFeature
          ? [
              {
                name: "Edit",
                onClick: () =>
                  navigate(
                    `/admin/org-management/${openRowMenuUser.organizationId}/users/${openRowMenuUser.email}/profile`,
                    {
                      state: {
                        from: "Users",
                      },
                    }
                  ),
              },
              openRowMenuUser?.active
                ? {
                    name: "Deactivate User",
                    onClick: () => setUserToDeactivate(openRowMenuUser),
                  }
                : {
                    name: "Activate User",
                    onClick: () => setUserToActivate(openRowMenuUser),
                  },
              openRowMenuUser?.direct_data_access_enabled
                ? {
                    name: "Disable Data Access",
                    onClick: () => setUserToDisableDataAccess(openRowMenuUser),
                  }
                : {
                    name: "Enable Data Access",
                    onClick: () => {
                      enableDataAccess({ email: openRowMenuUser.email }).then(
                        () => {
                          queryClient.invalidateQueries({
                            queryKey: ["users"],
                          });
                          toaster.success({
                            message: "Data access for user is enabled",
                          });
                        }
                      );
                    },
                  },
            ]
          : [
              {
                name: "Edit",
                onClick: () =>
                  navigate(
                    `/admin/org-management/${openRowMenuUser.organizationId}/users/${openRowMenuUser.email}/profile`
                  ),
              },
              openRowMenuUser?.active
                ? {
                    name: "Deactivate User",
                    onClick: () => setUserToDeactivate(openRowMenuUser),
                  }
                : {
                    name: "Activate User",
                    onClick: () => setUserToActivate(openRowMenuUser),
                  },
            ];

        return enableUserUpdate ? (
          <Popover
            placement="bottom-end"
            offset={3}
            actions={actions}
            TriggerComponent={(props) => {
              const { setRef, ...triggerProps } = props;

              return (
                <div
                  style={{
                    width: "50px",
                  }}
                >
                  <FontAwesomeIcon
                    data-cy="editMenu"
                    icon={faEllipsis}
                    style={{
                      width: "100%",
                      fontSize: "19px",
                      cursor: enableUserUpdate ? "pointer" : "default",
                      pointerEvents: enableUserUpdate ? "auto" : "none",
                      opacity: enableUserUpdate ? 1 : 0.5,
                    }}
                    ref={setRef}
                    {...triggerProps}
                  />
                </div>
              );
            }}
          />
        ) : null;
      },
    }),
  ];

  const columnVisibility = useMemo(
    () => ({
      directDataAccess: hasDataAccessFeature,
      actions: canUpdate,
    }),
    [canUpdate, hasDataAccessFeature]
  );

  const { currentUserPageNumber } = useNavigation();

  return (
    <>
      <Table
        data={data}
        columns={staticColumns.concat(dynamicColumns)}
        isRefreshing={isRefreshing}
        searchConfig={searchFilter}
        isLoading={isLoading}
        columnVisibility={columnVisibility}
        pageKey="Users"
        defaultState={{
          pagination: {
            pageSize: 20,
            pageIndex: currentUserPageNumber,
          },
        }}
      />

      {userToDeactivate ? (
        <DeactivateUserModal
          user={userToDeactivate}
          close={() => {
            setUserToDeactivate(null);
          }}
        />
      ) : null}

      {userToActivate ? (
        <ActivateUserModal
          user={userToActivate}
          close={() => {
            setUserToActivate(null);
          }}
        />
      ) : null}

      {userToDisableDataAccess ? (
        <DisableUserDataAccessModal
          user={userToDisableDataAccess}
          close={() => {
            setUserToDisableDataAccess(null);
          }}
        />
      ) : null}

      <Tooltip
        id={userDataAccessTooltipId}
        style={{ zIndex: "10000" }}
        place={"bottom"}
      />
    </>
  );
};

export default UserTable;
