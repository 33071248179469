import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { createPassword } from "modules/auth/actions";
import useFlash from "core/hooks/useFlash";
import useCurrentUser from "core/hooks/useCurrentUser";
import UnifiedAuthPageWrapper from "core/components/UnifiedAuthPageWrapper";
import Link from "core/components/Link";
import { Fieldset } from "core/components/Form/styles";
import { newAndConfirmPasswordsMatchError } from "common/validation";

const CreatePassword = () => {
  const { setError: setFlashError, setSuccess } = useFlash();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setIsPasswordExpired } = useCurrentUser();

  const email = searchParams.get("email")?.split(" ").join("+");
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const origin = searchParams.get("origin");

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validatePasswordsMatch = ({ newPassword, confirmPassword }) => {
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      throw newAndConfirmPasswordsMatchError;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const formData = new FormData(event.target);
      const formProps = Object.fromEntries(formData);

      validatePasswordsMatch(formProps);

      const result = await createPassword({
        ...formProps,
        mode,
        oobCode,
        email,
      });

      if (origin && origin === "tableau") {
        window.location.replace("https://tableau.populi.ai");
      } else {
        setSuccess(result.message);
        setIsPasswordExpired(false);
        setFlashError(null);
        navigate("/");
      }
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };

  return (
    <UnifiedAuthPageWrapper>
      <div className="form-wrapper">
        <div className="main-header">
          <h1 className="main-header__heading">Create password</h1>
          <p>Password must be least 8 characters in length.</p>
        </div>

        <form id="login-form" onSubmit={handleSubmit}>
          <Fieldset disabled={isLoading}>
            <div className="form-section">
              <div className="input__header">
                <label for="newPassword" className="input__label">
                  New Password
                </label>

                <span id="password-error" className="input-error">
                  Password is required
                </span>
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Enter new password"
                  autoComplete="new-password"
                  minlength="8"
                  required
                />

                <button
                  type="button"
                  id="toggle-password"
                  className="password__toggle"
                  onClick={(event) => {
                    const field = event.target.form.newPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="form-section">
              <div className="input__header">
                <label for="confirmPassword" className="input__label">
                  Confirm Password
                </label>

                <span id="confirmPassword-error" className="input-error">
                  Password is required
                </span>
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm new password"
                  autoComplete="new-password"
                  minlength="8"
                  required
                />

                <button
                  type="button"
                  id="toggle-confirmPassword"
                  className="password__toggle"
                  onClick={(event) => {
                    const field = event.target.form.confirmPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>

            {error && (
              <div id="form-error" className="form-error is-errored">
                {error}
              </div>
            )}

            <div className="form-section">
              <button className="button--primary">Create password</button>
            </div>

            <div className="form-extras">
              <div className="help-links">
                <Link to="/">Back to login</Link>
              </div>
            </div>
          </Fieldset>
        </form>
      </div>
    </UnifiedAuthPageWrapper>
  );
};

export default CreatePassword;
