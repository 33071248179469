import { Navigate } from "react-router-dom";

import Link from "core/components/Link";
import { useMemo, useState } from "react";
import { updatePassword } from "modules/auth/actions";
import useToaster from "core/hooks/useToaster";
import useCurrentUser from "core/hooks/useCurrentUser";
import { DateTime } from "luxon";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import UnifiedAuthPageWrapper from "core/components/UnifiedAuthPageWrapper";
import { Fieldset } from "core/components/Form/styles";

const ExpiringPassword = () => {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { toaster } = useToaster();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const daysRemaining = useMemo(() => {
    if (!currentUser) {
      return 0;
    }

    const passwordMeta = currentUser.passwordMeta;
    const passwordLastUpdated = DateTime.fromMillis(passwordMeta.lastUpdated);
    const daysSincePasswordLastUpdated = Math.floor(
      DateTime.now().diff(passwordLastUpdated, "days").toObject().days
    );

    return (
      currentUser.passwordMeta.expirationPeriod - daysSincePasswordLastUpdated
    );
  }, [currentUser]);

  const apiUpdatePassword = useAuthenticatedMutation(updatePassword);

  if (currentUser && !currentUser.isPasswordExpiring) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const formData = new FormData(event.target);
      const formProps = Object.fromEntries(formData);

      await apiUpdatePassword(formProps);

      toaster.success("Password has been updated successfully");
      dismiss();
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };

  const dismiss = () => {
    setCurrentUser((user) => ({ ...user, isPasswordExpiring: false }));
  };

  return (
    <UnifiedAuthPageWrapper>
      <div className="form-wrapper">
        <div className="main-header">
          <h1 className="main-header__heading">Update password</h1>
          <p>Password must be least 8 characters in length.</p>
        </div>
        <div
          className="application-message__text"
          style={{ marginBottom: "15px", marginTop: "-15px" }}
        >
          <p>
            Your password is set to expire in{" "}
            <b>
              {daysRemaining} day
              {daysRemaining !== 1 ? "s" : ""}
            </b>
            .
          </p>
        </div>

        <form id="login-form" onSubmit={handleSubmit}>
          <Fieldset disabled={isLoading}>
            <div className="form-section">
              <div className="input__header">
                <label for="oldPassword" className="input__label">
                  Old Password
                </label>

                <span id="password-error" className="input-error">
                  Password is required
                </span>
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  placeholder="Enter new password"
                  autocomplete="off"
                  minlength="12"
                  required
                />

                <button
                  type="button"
                  id="toggle-password"
                  className="password__toggle"
                  onClick={(event) => {
                    const field = event.target.form.oldPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="form-section">
              <div className="input__header">
                <label for="newPassword" className="input__label">
                  New Password
                </label>

                <span id="password-error" className="input-error">
                  Password is required
                </span>
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="password"
                  name="newPassword"
                  type="password"
                  placeholder="Enter new password"
                  autocomplete="off"
                  minlength="12"
                  required
                />

                <button
                  type="button"
                  id="toggle-password"
                  className="password__toggle"
                  onClick={(event) => {
                    const field = event.target.form.newPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="form-section">
              <div className="input__header">
                <label for="newPasswordConfirm" className="input__label">
                  Confirm Password
                </label>

                <span id="confirmPassword-error" className="input-error">
                  Password is required
                </span>
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="newPasswordConfirm"
                  name="newPasswordConfirm"
                  type="password"
                  placeholder="Confirm new password"
                  autocomplete="off"
                  minlength="8"
                  required
                />

                <button
                  type="button"
                  id="toggle-confirmPassword"
                  className="password__toggle"
                  onClick={(event) => {
                    const field = event.target.form.newPasswordConfirm;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>

            {error && (
              <div id="form-error" className="form-error is-errored">
                {error}
              </div>
            )}

            <div className="form-section">
              <button className="button--primary">Update password</button>
            </div>

            <div className="form-extras">
              <div className="help-links">
                <Link onClick={dismiss}>Remind me later</Link>
              </div>
            </div>
          </Fieldset>
        </form>
      </div>
    </UnifiedAuthPageWrapper>
  );
};

export default ExpiringPassword;
