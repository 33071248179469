import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, matchPath, useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { DateTime } from "luxon";

import PageNotFound from "pages/404";
import useCurrentUser from "core/hooks/useCurrentUser";
import useHasPermissions from "core/hooks/useHasPermissions";
import useToaster from "core/hooks/useToaster";
import Loader from "core/components/Loader";
import ProtectedLayout from "core/components/ProtectedLayout";
import { DURATION_30_MIN } from "common/utilities";
import { PERMISSIONS, ROLES } from "core/constants";

const protectedRoutes = [
  { to: "/" },
  { to: "/404" },
  { to: "/admin", permissions: "admin:read" },
  {
    to: "/admin/switch-org",
    permissions: "org-management:switch",
  },
  {
    to: "/admin/switch-org/:orgId",
    permissions: "org-management:switch",
  },
  {
    to: "/admin/org-management",
    permissions: "org-management:read",
  },
  {
    to: "/admin/org-management/create",
    permissions: "org-management:write",
  },
  {
    to: "/admin/org-management/:orgId",
    permissions: "user-management:read",
  },
  {
    to: "/admin/org-management/:organizationId/users/create",
    permissions: "user-management:write",
  },
  {
    to: "/admin/org-management/:organizationId/users/:email/profile/*",
    permissions: "user-management:write",
  },
  {
    to: "/admin/org-management/:orgId/update/*",
    permissions: "org-management:write",
  },
  {
    to: "/config/:orgId/managed-providers",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/aliases",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/relationships",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/update",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/update/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/remove",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/remove/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/update",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/update/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/remove",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/remove/:npi",
    permissions: "configurations:write",
  },
];

const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  return (
    <ProtectedLayout
      loggedInUser={currentUser}
      pageTitle="404"
      showBackLink={false}
      hideAppShell={false}
    >
      <PageNotFound hideGoBack={true} />
    </ProtectedLayout>
  );
};

const ProtectedRoute = ({
  pageTitle,
  backLinkText,
  backLinkTo,
  backLinkPermissions,
  hideAppShell = false,
  children,
}) => {
  const auth = useAuth();
  const location = useLocation();
  const params = useParams();
  const { toaster } = useToaster();

  const { currentUser, setCurrentUser } = useCurrentUser();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  console.log("PROTECTED_ROUTE", { auth, currentUser });

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      auth.isAuthenticated &&
      !auth.isLoading &&
      !currentUser
    ) {
      const now = Date.now();
      const passwordMeta = auth.user.profile.password_meta;
      const passwordLastUpdated = DateTime.fromMillis(passwordMeta.lastUpdated);
      const daysSincePasswordLastUpdated = DateTime.now()
        .diff(passwordLastUpdated, "days")
        .toObject().days;
      const daysRemaining =
        passwordMeta.expirationPeriod - daysSincePasswordLastUpdated;
      const isPasswordExpiring =
        daysRemaining <= passwordMeta.updateReminderPeriod;

      // Todo: Will need to be handled on OIDC server in future
      // if (error.resetLink) {
      //   setError(error.message);
      //   const url = new URL(error.resetLink);
      //   navigate(`${url.pathname}${url.search}`, { replace: true });
      //   setIsPasswordExpired(true);

      const loggedInUser = {
        email: auth.user.profile.email,
        name: auth.user.profile.name,
        organizationId: auth.user.profile.org_info?.id,
        orgName: auth.user.profile.org_info?.name,
        accessToken: auth.user.access_token,
        refreshToken: auth.user.refresh_token,
        role: ROLES[auth.user.profile.role] || "Org User",
        permissions: PERMISSIONS[ROLES[auth.user.profile.role]] || [],
        expirationTime: now + DURATION_30_MIN,
        lastActiveTime: now,
        passwordMeta,
        isPasswordExpiring,
      };

      setCurrentUser(loggedInUser);
    }
  }, [auth, currentUser, setCurrentUser]);

  useEffect(() => {
    if (location.state?.error) {
      toaster.error({ message: location.state.error });
      console.log("UNAUTHORIZED URL", location.pathname);
    } else {
      toaster.clear();
    }
    console.log(location.state);
  }, [location.state, location.pathname, toaster]);

  const matchedRoute = protectedRoutes.find((route) =>
    matchPath(route.to, location.pathname)
  );

  const userIsAuthorized = useHasPermissions(
    currentUser?.permissions,
    matchedRoute.permissions,
    { userEmail: currentUser?.email, paramEmail: params?.email }
  );

  const showBackLink = useHasPermissions(
    currentUser?.permissions,
    backLinkPermissions
  );

  if (auth.isLoading || !currentUser) {
    return <Loader />;
  }

  if (!auth.isLoading && !auth.user) {
    return (
      <Navigate
        to="/error"
        state={{
          error:
            "Current user does not exist. Please contact support for assistance.",
        }}
        replace
      />
    );
  }

  if (!userIsAuthorized) {
    return (
      <Navigate
        to="/"
        state={{ error: "You do not have permission to view that page." }}
        replace
      />
    );
  }

  if (currentUser?.isPasswordExpiring) {
    return <Navigate to="/expiring-password" />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
      <ProtectedLayout
        pageTitle={pageTitle}
        backLinkText={backLinkText}
        backLinkTo={backLinkTo}
        showBackLink={showBackLink}
        hideAppShell={hideAppShell}
      >
        {children}
      </ProtectedLayout>
    </ErrorBoundary>
  );
};

export default ProtectedRoute;
