import { useState } from "react";
import { DateTime } from "luxon";
import { z } from "zod";

import Typography from "core/components/Typography";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Field from "core/components/Field";
import useToaster from "core/hooks/useToaster";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useForm from "core/hooks/useForm";
import {
  validate,
  required,
  password,
  doesNotMatchCurrentPassword,
  newAndConfirmPasswordsMatch,
} from "common/validation";
import { updatePassword } from "modules/auth/actions";
import DetailsViewer from "../DetailsViewer";

const formSchema = z.object({
  oldPassword: z.string().min(8),
  newPassword: z.string().min(8),
  newPasswordConfirm: z.string().min(8),
});

const defaultValues = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export const ChangePasswordNavSection = ({ user, onMutationSuccess }) => {
  const [error, setError] = useState("");
  const { toaster } = useToaster();

  const apiUpdatePassword = useAuthenticatedMutation(updatePassword);

  const onSubmit = async (values) => {
    setError("");

    try {
      await apiUpdatePassword(values);
      toaster.success("Password has been updated successfully");
      onMutationSuccess();
    } catch (error) {
      setError(error);
    }
  };

  const { formValues, errors, isDisabled, onFormSubmit, onFieldChange } =
    useForm({
      placeholderValues: defaultValues,
      initialValues: defaultValues,
      disabledUntilTouched: true,
      onSubmit,
      formSchema,
    });

  return (
    <>
      <Typography
        variant="p"
        noMargin
        style={{ lineHeight: "22px", marginBottom: "24px" }}
      >
        Passwords are valid for 60 days, at which point you will be asked to
        change your password. Password must be at least 8 characters in length.
      </Typography>

      <Form
        onSubmit={onFormSubmit}
        submitLabel={"Change Password"}
        disabled={isDisabled}
      >
        <Error>{error}</Error>
        <Field
          type="password"
          id="oldPassword"
          labelText="Current Password"
          isRequired={true}
          value={formValues.oldPassword}
          error={errors.oldPassword}
          onChange={onFieldChange("oldPassword")}
          validator={validate(required(), password())}
        />
        <Field
          type="password"
          id="newPassword"
          labelText="New Password"
          isRequired={true}
          value={formValues.newPassword}
          error={errors.newPassword}
          onChange={onFieldChange("newPassword")}
          validator={validate(
            required(),
            password(),
            doesNotMatchCurrentPassword(formValues.oldPassword)
          )}
        />
        <Field
          type="password"
          id="newPasswordConfirm"
          labelText="Confirm New Password"
          isRequired={true}
          value={formValues.newPasswordConfirm}
          error={errors.newPasswordConfirm}
          onChange={onFieldChange("newPasswordConfirm")}
          validator={validate(
            required(),
            password(),
            newAndConfirmPasswordsMatch(formValues.newPassword)
          )}
        />
      </Form>

      <DetailsViewer
        style={{ paddingTop: 4 }}
        isLoading={false}
        data={[
          {
            label: "Last Changed",
            value:
              user.last_password_update_timestamp &&
              DateTime.fromMillis(user.last_password_update_timestamp).toFormat(
                "MMMM d, yyyy 'at' h:mm a"
              ),
          },
        ]}
      />
    </>
  );
};
