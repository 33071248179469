import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { faCity } from "@fortawesome/pro-regular-svg-icons";
import { ErrorBoundary } from "react-error-boundary";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useCurrentUser from "core/hooks/useCurrentUser";
import useToaster from "core/hooks/useToaster";
import PageHeader from "core/components/PageHeader";
import Search from "core/components/Search";
import Button from "core/components/Button";
import TableErrorFallback from "core/components/Table/TableErrorFallback";
import OrganizationTable from "modules/admin/OrganizationTable";
import { getOrganizations } from "modules/admin/actions";
import { Row } from "core/styles";
import { ROLES } from "core/constants";
import useNavigation from "core/hooks/useNavigation.js";

const OrganizationManagement = () => {
  const navigate = useNavigate();
  const { toaster } = useToaster();
  const location = useLocation();

  const fetchQueryCall = useAuthenticatedCall(getOrganizations);
  const {
    isLoading,
    data: organizations,
    fetchStatus,
    isError,
    error,
  } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchQueryCall,
    staleTime: 20 * 1000,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isError) {
      if (Array.isArray(error)) {
        toaster.error({ message: error[0] });
      } else {
        toaster.error({ message: error.message });
      }
    }
  }, [isError, error, toaster]);

  useEffect(() => {
    const pathname = location.pathname; //this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname]);

  const { orgSearchValue, setOrgSearchValue } = useNavigation();
  const handleSearchChange = (event) => {
    setOrgSearchValue(event.target.value);
  };

  const {
    currentUser: { role },
  } = useCurrentUser();
  const canUpdateOrganizations = role === ROLES.systemAdmin;

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <PageHeader
        title="Organizations"
        icon={faCity}
        iconBackgroundColor="#D68712"
        iconAlt="Organizations"
        PageActionsComponent={
          <Row>
            <Search value={orgSearchValue} onChange={handleSearchChange} />
            {canUpdateOrganizations && (
              <Button
                width="auto"
                onClick={() => navigate("/admin/org-management/create")}
              >
                Create Organization
              </Button>
            )}
          </Row>
        }
      />
      <ErrorBoundary FallbackComponent={TableErrorFallback}>
        <OrganizationTable
          data={organizations || []}
          isRefreshing={fetchStatus === "fetching"}
          isLoading={isLoading}
          canUpdate={canUpdateOrganizations}
          searchFilter={{
            searchValue: orgSearchValue,
            columns: [
              "name",
              "id",
              "contactName",
              "contactEmail",
              "contactPhone",
              "domain",
            ],
          }}
        />
      </ErrorBoundary>
    </div>
  );
};

export default OrganizationManagement;
