import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import { FormContainer } from "core/components/Form";
import {
  createOrganization,
  updateOrganizationLicenses,
} from "modules/admin/actions";
import OrganizationForm from "modules/admin/OrgDetailsForm";
import useToaster from "core/hooks/useToaster";
import styled from "styled-components";
import OrganizationLicenseForm from "modules/admin/OrgUserLicenseForm";
import { animated, useTransition } from "react-spring";
import CloseButton from "core/components/CloseButton";
import { DEFAULT_ORG } from "core/constants";
import Typography from "core/components/Typography";

const FormWizardContainer = styled.div`
  padding: 15px;
  margin-bottom: 35px;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 300px;
  grid-gap: 35px;
  justify-content: center;
`;

const FormWizardStep = styled.div`
  padding: 10px 0px;

  &::before {
    content: "";
    display: block;
    position: relative;
    left: 0px;
    top: -5px;
    width: calc(100%);
    height: 3px;
    background-color: ${(props) =>
      props.isComplete ? "#008844" : props.isActive ? "#007EAC" : "#F5F2FC"};
    opacity: 1;
  }

  &::target-text {
    opacity: ${(props) => (props.isComplete ? 0.5 : 1)};
  }

  span {
    opacity: ${(props) => (props.isComplete ? 0.5 : 1)};
  }

  span:first-child {
  }

  span:last-child {
    display: block;
    font-weight: bold;
    font-size: 22px;
  }
`;

const CreateOrg = () => {
  const { toaster } = useToaster();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [newOrgId, setNewOrgId] = useState(null);

  const createOrg = useAuthenticatedMutation(createOrganization);

  const updateLicenses = useAuthenticatedMutation((req) =>
    updateOrganizationLicenses({ ...req, organizationId: newOrgId })
  );

  const createOrgMutation = useMutation({
    mutationFn: createOrg,
    onSuccess: (data, variables, context) => {
      // Remember org id
      setNewOrgId(variables.id);

      // Go to next step
      setActiveStep(1);

      // Add org to cached query results
      queryClient.setQueryData(["organizations"], (cachedList) =>
        [
          {
            isUpdated: true,
            userCount: {
              explorer: 0,
              viewer: 0,
              creator: 0,
              total: 0,
            },
            ...variables,
          },
        ].concat(cachedList || [])
      );

      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey: ["organizations"] });

      // Notify
      toaster.success({ message: "Organization has been created." });
    },
  });

  const licenseUpdatesMutation = useMutation({
    mutationFn: updateLicenses,
    onSuccess: (data, variables) => {
      // Update cached query results
      queryClient.setQueryData(["organizations"], (cachedList) => {
        if (!cachedList) {
          return [];
        }
        return cachedList.map((cachedOrg) =>
          cachedOrg.id === newOrgId
            ? { ...cachedOrg, variables, isUpdated: true }
            : cachedOrg
        );
      });

      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey: ["organizations"] });

      // Close form
      navigate("/admin/org-management");

      // Notify
      toaster.success({ message: "Licenses have been set." });
    },
  });

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      title: "Organization Details",
      form: (
        <OrganizationForm
          onSubmit={createOrgMutation.mutateAsync}
          onCancel={null}
          error={createOrgMutation.error}
          isSubmitting={createOrgMutation.isPending}
          initialValues={DEFAULT_ORG}
        />
      ),
    },
    {
      title: "User Licenses",
      form: (
        <OrganizationLicenseForm
          onSubmit={licenseUpdatesMutation.mutateAsync}
          submitLabel="Finish"
          defaultValues={DEFAULT_ORG}
          error={licenseUpdatesMutation.error}
          isSubmitting={licenseUpdatesMutation.isPending}
        />
      ),
    },
  ];

  const right = 100;
  const left = -100;
  const middle = 0;
  const transitions = useTransition(activeStep, {
    initial: () => ({ x: 0, opacity: 0 }),
    enter: () => ({ x: middle, opacity: 1 }),
    from: () => ({
      x: right,
      opacity: 0,
    }),
    leave: () => ({
      x: left,
      opacity: 0,
    }),
    exitBeforeEnter: true,
  });

  useEffect(() => {
    window.history.pushState(
      { from: "Organizations" },
      "",
      "/admin/org-management/create"
    );
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div style={{ paddingTop: "65px", position: "relative" }}>
        <CloseButton />

        <FormWizardContainer>
          {steps.map((step, stepNumber) => {
            const { title } = step;

            return (
              <FormWizardStep
                key={stepNumber}
                isComplete={stepNumber < activeStep}
                isActive={stepNumber === activeStep}
              >
                <span>{`Step ${stepNumber + 1}:`}</span>
                <Typography variant="h3">{title}</Typography>
              </FormWizardStep>
            );
          })}
        </FormWizardContainer>

        <FormContainer
          style={{
            minHeight: "600px",
            margin: "0px auto",
            position: "relative",
          }}
        >
          {transitions((style, stepIndex) => {
            return (
              <animated.div
                data-cy="form-animation"
                style={{ ...style, position: "relative", width: "100%" }}
              >
                {steps[stepIndex].form}
              </animated.div>
            );
          })}
        </FormContainer>
      </div>
    </div>
  );
};

export default CreateOrg;
